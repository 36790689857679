import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import esLocale from "date-fns/locale/es";
import Dropdown from './Dropdown';
import {
    downloadModel,
    clearModelDownload,
    clearModelUpload,
    clearModelPreview,
    updateInforme,
    uploadModel,
    previewInforme
} from '../actions';


class Informes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedInforme: null,
            selectedInformeLabel: '',
            newInformeMes: null,
            fileInput: false,
            fileInputName: 'Archivo',
        };
        this.fileInputRef = React.createRef();
        this.iframeRef = React.createRef();
        this.setInformeMes = this.setInformeMes.bind(this);
        this.selectInforme = this.selectInforme.bind(this);
        this.getDownloadLink = this.getDownloadLink.bind(this);
        this.handleDownloadLinkClick = this.handleDownloadLinkClick.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleActosUpload = this.handleActosUpload.bind(this);
        this.handleFileInput = this.handleFileInput.bind(this);
        this.handleErrorDownloadLinkClick = this.handleErrorDownloadLinkClick.bind(this);
        this.previewInforme = this.previewInforme.bind(this);
        this.closePreview = this.closePreview.bind(this);
    }

    selectInforme(informe) {
        this.setState({selectedInforme: informe.value, selectedInformeLabel: informe.label});
        this.props.dispatch(clearModelPreview('informes'));
    }

    getDownloadLink() {
        this.props.dispatch(downloadModel('informes', null, {mes: this.state.selectedInforme}));
        this.setState({selectedInforme: null, selectedInformeLabel: ''});
    }

    handleDownloadLinkClick() {
        this.props.dispatch(clearModelDownload('informes'));
        this.props.dispatch(clearModelPreview('informes'));
        window.location.assign(this.props.informes.downloadLink);
    }

    setInformeMes() {
        this.props.dispatch(updateInforme({mes: this.state.newInformeMes}));
        this.setState({newInformeMes: null});
    }

    handleDateChange(date) {
        let newDate = null;
        if (date) {
            let month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
            if (month.length < 2) month = '0' + month;
            newDate = [year, month, day].join('-');
        }
        this.setState({newInformeMes: newDate}); 
    }

    handleFileInput(event) {
        if (event.target.value) {
            const fileName = event.target.value.split('\\').pop();
            this.setState({fileInput: true, fileInputName: fileName});
        }
    }

    handleActosUpload() {
        var data = new FormData();
        data.append('csv', document.getElementById('acto-csv-file').files[0]);
        this.props.dispatch(uploadModel('actos', data));
        this.fileInputRef.current.value = null;
        this.setState({
            fileInput: false,
            fileInputName: 'Archivo'
        });
    }

    handleErrorDownloadLinkClick() {
        this.props.dispatch(clearModelUpload('actos'));
        window.location.assign(this.props.actos.upload.errorDownloadLink);
    }

    previewInforme() {
        this.props.dispatch(previewInforme(this.state.selectedInforme));
    }

    closePreview() {
        this.props.dispatch(clearModelPreview('informes'));
    }

    componentDidUpdate(prevProps) {
        if (this.props.informes.preview != prevProps.informes.preview && this.iframeRef.current) {
            const iframeDoc = this.iframeRef.current.contentDocument || this.iframeRef.current.contentWindow.document;
            iframeDoc.write(JSON.parse(this.props.informes.preview));
            iframeDoc.close();
        }
    }

    render() {
        return (
            <Fragment>
                <Paper style={{padding: 8, marginBottom: 16}} elevation={4}>
                    <div style={{padding: '4px 0 10px 2px'}}>
                        <Typography variant="h6" component="h6">
                            Actualizar
                        </Typography>
                    </div>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <DatePicker
                                    clearable
                                    views={["year", "month"]}
                                    clearLabel='Limpiar'
                                    cancelLabel='Cancelar'
                                    format="yyyy-MM"
                                    margin='normal'
                                    label='Mes'
                                    value={this.state.newInformeMes}
                                    onChange={date => this.handleDateChange(date)}
                                /> 
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="primary" onClick={this.setInformeMes} disabled={!this.state.newInformeMes}>
                                Actualizar mes
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper style={{padding: '8px 8px 30px 8px', marginBottom: 16}} elevation={4}>
                    <div style={{padding: '4px 0 10px 2px'}}>
                        <Typography variant="h6" component="h6">
                            Descargar
                        </Typography>
                    </div>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={6}>
                            <Dropdown 
                                model='informes' 
                                name='Informe'
                                value={{
                                    value: this.state.selectedInforme, 
                                    label: this.state.selectedInformeLabel
                                }}
                                label={this.state.selectedInformeLabel}
                                changeValue={value => this.selectInforme(value)} 
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {(this.props.informes.downloadLink) ?
                                <Button variant="contained" color="primary" onClick={this.handleDownloadLinkClick}>
                                    Descargar
                                </Button>
                            :
                                <Fragment>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={this.getDownloadLink} 
                                        disabled={!this.state.selectedInforme}
                                        style={{marginRight: 10}}
                                    >
                                        Generar descarga
                                    </Button>
                                    {(this.props.informes.preview) ?
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            onClick={this.closePreview} 
                                            disabled={!this.state.selectedInforme}
                                        >
                                            Cerrar
                                        </Button>
                                    :
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            onClick={this.previewInforme} 
                                            disabled={!this.state.selectedInforme}
                                        >
                                            Ver
                                        </Button>
                                    }
                                </Fragment>
                            }
                        </Grid>
                        {(this.props.informes.preview) ?
                            <Grid item xs={12} md={12}>
                                <iframe title="informe-preview" ref={this.iframeRef} style={{width: '100%', height: 400}}>
                                </iframe>
                            </Grid>
                        : null }
                    </Grid>
                </Paper>
                <Paper style={{padding: 8, marginBottom: 16}} elevation={4}>
                    <div style={{padding: '4px 0 10px 2px'}}>
                        <Typography variant="h6" component="h6">
                            Cargar
                        </Typography>
                    </div>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={6} style={{textAlign: 'right'}}>
                            <input
                                accept="text/csv"
                                style={{display: 'none'}}
                                id="acto-csv-file"
                                type="file"
                                onChange={this.handleFileInput}
                                ref={this.fileInputRef}
                            />
                            <label htmlFor="acto-csv-file">
                                <Button variant="contained" component="span">
                                    {this.state.fileInputName}
                                </Button>
                            </label>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" color="primary" onClick={this.handleActosUpload} disabled={!this.state.fileInput}>
                                Cargar actos
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {(this.props.actos.upload.successCount !== null) ? 
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">Creados</TableCell>
                                            <TableCell align="right">Errores</TableCell>
                                            <TableCell align="right">Descargar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right">{this.props.actos.upload.successCount}</TableCell>
                                            <TableCell align="right">{this.props.actos.upload.errorCount}</TableCell>
                                            <TableCell align="right">
                                                {(this.props.actos.upload.errorDownloadLink) ?
                                                    <Button variant="contained" color="primary" onClick={this.handleErrorDownloadLinkClick}>
                                                        Descargar
                                                    </Button>
                                                : null}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            : null }
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment>
        )
    }
}

Informes.propTypes = {
}

function mapStateToProps(state) {
    return {
        informes: state.models.informes,
        actos: state.models.actos
    }
}

export default connect(mapStateToProps)(Informes)
