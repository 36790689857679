import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dropdown from './Dropdown';
import {
    createModel 
} from '../actions';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2
    },
    heading: {
        padding: '8px 8px 16px 8px'
    },
    buttonContainer: {
        textAlign: 'right'
    },
    button: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit
    }
});


class NewTipoDeActo extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            values: {
                compania_id: null,
                nombre: '',
                precio: ''
            },
            labels: {
                compania_id: null,
            }
        }
        this.state = this.initialState;
        this.reset = this.reset.bind(this);
        this.submit = this.submit.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(key, value, label = null) {
        const values = Object.assign({}, this.state.values, {[key]: value});
        let labels = this.state.labels;
        if (label) {
            labels = Object.assign({}, this.state.labels, {[key]: label});
        }
        this.setState({values: values, labels: labels}); 
    }

    reset() {
        this.setState(this.initialState);
    }

    submit() {
        this.props.dispatch(createModel('tipos_de_actos', this.state.values));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request.isRequesting === true && 
            this.props.request.isRequesting === false &&
            this.props.request.resource === 'tipos_de_actos' &&
            this.props.request.method === 'POST' &&
            this.props.request.success === true
        ) {
            this.reset();
            this.props.created();
        }
    }

    isComplete() {
        return (
            this.state.values.nombre !== null && this.state.values.compania_id !== '' &&
            this.state.values.precio !== ''
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={4}>
                <div className={classes.heading}>
                    <Typography variant="h6" component="h6">
                        Nuevo Tipo De Acto
                    </Typography>
                </div>
                <Grid container spacing={24}>
                    <Grid item xs={12} md={4}>
                        <Dropdown 
                            model='companias' 
                            name='Compañía'
                            value={{
                                value: this.state.values.compania_id, 
                                label: this.state.labels.compania_id
                            }}
                            label={this.state.labels.compania}
                            changeValue={
                                (selected) => this.changeValue('compania_id', selected.value, selected.label)
                            } 
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="nombre"
                            label="Nombre"
                            value={this.state.values.nombre}
                            onChange={(event) => 
                                this.changeValue('nombre', event.target.value)
                            }
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="precio"
                            label="Precio"
                            value={this.state.values.precio}
                            onChange={(event) => 
                                this.changeValue('precio', event.target.value)
                            }
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" className={classes.button} onClick={this.reset}>
                        Limpiar
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.button} 
                        onClick={this.submit} 
                        disabled={!this.isComplete()}
                    >
                        Crear
                    </Button>
                </div>
            </Paper>
        );
    }
}

NewTipoDeActo.propTypes = {
    classes: PropTypes.object.isRequired,
    request: PropTypes.shape({
        isRequesting: PropTypes.bool, 
        resource: PropTypes.string, 
        method: PropTypes.string,
        success: PropTypes.bool,
        error: PropTypes.bool
    })
};

function mapStateToProps(state) {
    return {
        request: state.request
    }
}

export default withStyles(styles)(connect(mapStateToProps)(NewTipoDeActo));

