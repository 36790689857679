import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import {
  fetchSearchResults
} from '../actions'


const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 101,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});


function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

function SelectComponent(props) {
    if (props.creatable) {
        return (
            <AsyncCreatableSelect {...props} />
        );
    } else {
        return (
            <AsyncSelect {...props} />
        );
    }
}

function debounce(func, wait, immediate) {
	let timeout;
	return function() {
		const context = this, args = arguments;
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.loadOptions = debounce(this.loadOptions.bind(this), 250);
        this.handleChange = this.handleChange.bind(this);
    }

    async loadOptions(input, callback) {
        if (input.length >= 3) {
            await this.props.dispatch(fetchSearchResults(this.props.model, input, this.props.compania_id));
            callback(this.props.searchResults);
        }
    }

    handleChange(item, change) {
        if (change.action === 'select-option' || change.action === 'create-option') {
            this.props.changeValue(item);
        } else if (change.action === 'clear') {
            this.props.changeValue({value: null, label: null});
        }
    }

    getValue() {
        if (this.props.value.value === null) {
            return null;
        } else {
            return this.props.value;
        }
    }

    render() {
        const { classes, theme } = this.props;
        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };
        return (
            <NoSsr>
                <SelectComponent 
                    ref={this.refSelect}
                    classes={classes}
                    styles={selectStyles}
                    components={components}
                    value={this.getValue()}
                    loadOptions={this.loadOptions}
                    isClearable={true}
                    onChange={this.handleChange}
                    placeholder={this.props.name}
                    isDisabled={this.props.compania_id === false}
                    creatable={this.props.creatable}
                    noOptionsMessage={() => 'No hay opciones'}
                    loadingMessage={() => 'Cargando'}
                    formatCreateLabel={(name) => `Crear nuevo - ${name}`}
                />
            </NoSsr>
        )
    }
}

Dropdown.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    model: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    searchResults: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        searchResults: state.models[ownProps.model].searchResults
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null, null, { forwardRef: true })(Dropdown));
