import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from './Dropdown';
import {
    updateModel,
    deleteModel
} from '../actions';

class EditTipoDeActo extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            values: {
                compania_id: this.props.details[4],
                nombre: this.props.details[2],
                precio: this.props.details[3]
            },
            labels: {
                compania_id: this.props.details[1],
            },
            anchorEl: null
        }
        this.state = this.initialState;
        this.reset = this.reset.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleDeleteMenu = this.toggleDeleteMenu.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    reset() {
        this.setState(this.initialState);
    }

    submit() {
        this.props.dispatch(updateModel('tipos_de_actos', this.props.details[0], this.state.values));
    }

    delete() {
        this.props.dispatch(deleteModel('tipos_de_actos', this.props.details[0]));
    }

    toggleDeleteMenu(event) {
        let newTarget = null;
        if (!this.state.anchorEl) {
            newTarget = event.currentTarget;
        }
        this.setState({ anchorEl: newTarget });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request.isRequesting === true && 
            this.props.request.isRequesting === false &&
            this.props.request.resource === 'tipos_de_actos' &&
            (this.props.request.method === 'DELETE' || this.props.request.method === 'PUT') &&
            this.props.request.success === true
        ) {
            this.props.update();
            this.props.close();
        }
    }

    changeValue(key, value, label = null) {
        let newValues = {[key]: value};
        let newLabels = {[key]: label};
        const values = Object.assign({}, this.state.values, newValues);
        const labels = Object.assign({}, this.state.labels, newLabels);
        this.setState({values: values, labels: labels});
    }

    handleKeyPress(event) {
        if (event.key === 'Enter' && this.isComplete()) {
            this.submit();
        }
    }

    isComplete() {
        return (
            this.state.values.compania_id !== null &&
            this.state.values.nombre !== '' &&
            this.state.values.precio !== ''
        );
    }

    render() {
        const { anchorEl } = this.state;
        return (
            <Dialog open={true} onClose={this.props.close} fullWidth={true} maxWidth='lg' onKeyPress={this.handleKeyPress}>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={4}>
                            <Dropdown 
                                model='companias' 
                                name='Compañía'
                                value={{
                                    value: this.state.values.compania_id, 
                                    label: this.state.labels.compania_id
                                }}
                                label={this.state.labels.compania}
                                changeValue={
                                    (selected) => this.changeValue('compania_id', selected.value, selected.label)
                                } 
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="nombre"
                                label="Nombre"
                                value={this.state.values.nombre}
                                onChange={(event) => 
                                    this.changeValue('nombre', event.target.value)
                                }
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="precio"
                                label="Precio"
                                value={this.state.values.precio}
                                onChange={(event) => 
                                    this.changeValue('precio', event.target.value)
                                }
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {this.props.details[5] === 0 ? (
                        <Fragment>
                            <Button 
                                variant="contained" 
                                aria-owns={anchorEl ? 'delete-menu' : undefined}
                                aria-haspopup='true'
                                onClick={this.toggleDeleteMenu}
                                color='secondary'
                            >
                                Borrar
                            </Button>
                            <Menu
                                id='delete-menu'
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.toggleDeleteMenu}
                            >
                                <MenuItem onClick={this.delete}>De verdad</MenuItem>
                            </Menu>
                        </Fragment>
                    ) : null 
                    }
                    <Button variant="contained" onClick={this.reset} color='default'>
                        Restaurar
                    </Button>
                    <Button variant="contained" onClick={this.submit} disabled={!this.isComplete()} color='primary'>
                        Editar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditTipoDeActo.propTypes = {
};

function mapStateToProps(state) {
    return {
        request: state.request
    }
}

export default connect(mapStateToProps)(EditTipoDeActo);
