import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ActoForm from './ActoForm';
import {
    updateModel,
    duplicateModel,
    deleteModel
} from '../actions';

class EditActo extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            values: {
                fecha: this.props.details[1],
                informe: this.props.details[2],
                talon: this.props.details[10], 
                autorizacion: this.props.details[11],
                tarjeta: this.props.details[12],
                observaciones: this.props.details[13],
                paciente_id: this.props.details[14],
                hospital_id: this.props.details[15],
                medico_id: this.props.details[16],
                tipo_de_acto_id: this.props.details[17],
                compania_id: this.props.details[18],
                TVS: this.props.details[19],
            },
            labels: {
                paciente_id: this.props.details[3],
                hospital_id: this.props.details[4],
                medico_id: this.props.details[5],
                tipo_de_acto_id: this.props.details[6],
                compania_id: this.props.details[7],
            },
            deleteAnchorEl: null,
            duplicateAnchorEl: null,
        }
        this.state = this.initialState;
        this.reset = this.reset.bind(this);
        this.submit = this.submit.bind(this);
        this.duplicate = this.duplicate.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleDeleteMenu = this.toggleDeleteMenu.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    reset() {
        this.setState(this.initialState);
    }

    duplicate() {
        this.props.dispatch(duplicateModel('actos', this.props.details[0]));
    }

    submit() {
        this.props.dispatch(updateModel('actos', this.props.details[0], this.state.values));
    }

    delete() {
        this.props.dispatch(deleteModel('actos', this.props.details[0]));
    }

    toggleDeleteMenu(event) {
        let newTarget = null;
        if (!this.state.deleteAnchorEl) {
            newTarget = event.currentTarget;
        }
        this.setState({ deleteAnchorEl: newTarget });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request.isRequesting === true && 
            prevProps.request.resource === 'actos' &&
            this.props.request.isRequesting === false &&
            this.props.request.error === false
        ) {
            this.props.update();
            this.props.close();
        }
    }

    changeValue(key, value, label = null, extras = null, clear = null) {
        let newValues = {[key]: value};
        let newLabels = {[key]: label};
        if (clear) {
            newValues[clear] = null;
            newLabels[clear] = null;
        }
        const values = Object.assign({}, this.state.values, newValues);
        const labels = Object.assign({}, this.state.labels, newLabels);
        this.setState({values: values, labels: labels});
    }

    handleKeyPress(event) {
        if (event.key === 'Enter' && this.isComplete()) {
            this.submit();
        }
    }

    isComplete() {
        const vals = this.state.values;
        return (
            vals.fecha !== null &&
            vals.informe !== null &&
            vals.hospital_id !== null &&
            vals.paciente_id !== null &&
            vals.medico_id !== null &&
            vals.compania_id !== null &&
            vals.tipo_de_acto_id !== null &&
            (vals.tarjeta !== '' || vals.TVS)
        );
    }

    render() {
        const { deleteAnchorEl } = this.state;
        return (
            <Dialog open={true} onClose={this.props.close} fullWidth={true} maxWidth='lg' onKeyPress={this.handleKeyPress}>
                <DialogContent>
                    <ActoForm 
                        values={this.state.values} 
                        labels={this.state.labels} 
                        changeValue={this.changeValue}            
                        informe
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" 
                        aria-owns={deleteAnchorEl ? 'delete-menu' : undefined}
                        aria-haspopup='true'
                        onClick={this.toggleDeleteMenu}
                        color='secondary'
                    >
                        Borrar
                    </Button>
                    <Menu
                        id='delete-menu'
                        anchorEl={deleteAnchorEl}
                        open={Boolean(deleteAnchorEl)}
                        onClose={this.toggleDeleteMenu}
                    >
                        <MenuItem onClick={this.delete}>De verdad</MenuItem>
                    </Menu>
                    <Button variant="contained" onClick={this.duplicate} color='default'>
                        Duplicar
                    </Button>
                    <Button variant="contained" onClick={this.reset} color='default'>
                        Restaurar
                    </Button>
                    <Button variant="contained" onClick={this.submit} disabled={!this.isComplete()} color='primary'>
                        Editar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditActo.propTypes = {
    request: PropTypes.shape({
        isRequesting: PropTypes.bool, 
        resource: PropTypes.string, 
        method: PropTypes.string,
        success: PropTypes.bool,
        error: PropTypes.bool
    })
};

function mapStateToProps(state) {
    return {
        request: state.request,
    }
}

export default connect(mapStateToProps)(EditActo);
