import fetch from 'cross-fetch';
import AuthService from './AuthService';

export const MAKE_REQUEST = 'MAKE_REQUEST';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const REPLACE_SEARCH_RESULTS = 'REPLACE_SEARCH_RESULTS';

export const CLEAR_DOWNLOAD = 'CLEAR_DOWNLOAD';
export const CLEAR_UPLOAD = 'CLEAR_UPLOAD';
export const CLEAR_PREVIEW = 'CLEAR_PREVIEW';

const apiURL = 'https://sama-api.manageproject.online';

function getHeaders() {
    const token = AuthService.getToken();
    return {
        'token': token
    };
}

function clearToken() {
    AuthService.clearToken();
}

function requestWrapper(request, functions) {
    return dispatch => {
        dispatch(functions.beforeAction());
        return fetch(request.url, {headers: request.headers, method: request.method, body: request.body})
            .then(
                response => {
                    if (response.status === 401) {
                        functions.unauthorized();
                        throw Error('No autorizado');
                    } else if (!response.ok) {
                        throw Error('Error');
                    }
                    return response.json()
                }
            )
            .then(json => { 
                if (functions.hasOwnProperty('success'))
                    functions.success(json);
                dispatch(functions.successAction(json)) 
            })
            .catch(error => { dispatch(functions.errorAction(error)) } );
    };
}

function makeRequest() {
    return {
        type: MAKE_REQUEST,
        subtype: this.subtype,
        timestamp: Date.now(),
        resource: this.resource,
        method: this.method,
    };
}

function requestSuccess(response) {
    return {
        type: REQUEST_SUCCESS,
        subtype: this.subtype,
        timestamp: Date.now(),
        resource: this.resource,
        method: this.method,
        response: response,
    };
}

function requestError(error) {
    return {
        type: REQUEST_ERROR,
        subtype: this.subtype,
        timestamp: Date.now(),
        resource: this.resource,
        method: this.method,
    };
}

export function login(credentials) {
    const request = {
        resource: 'token',
        subtype: 'auth',
        url: `${apiURL}/token`,
        headers: null,
        method: 'POST', 
        body: JSON.stringify(credentials)
    };
    const functions = {
        beforeAction: makeRequest.bind(request), 
        unauthorized: requestError.bind(request),
        success: json => AuthService.storeDetails(json),
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request),
    }
    return requestWrapper(request, functions);
}

export function logout(all = false) {
    let url = `${apiURL}/token`;
    if (all) 
        url = `${apiURL}/tokens`;
    const request = {
        resource: 'token',
        subtype: 'auth',
        url: url,
        headers: getHeaders(),
        method: 'DELETE', 
        body: null
    };
    const functions = {
        beforeAction: makeRequest.bind(request),
        unauthorized: clearToken,
        success: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function fetchSearchResults(model, term, compania = null) {
    let url = apiURL + '/' + model + '/busca/' + term;
    if (compania) {
        url = apiURL + '/companias/' + compania + '/' + model + '/busca/' + term;
    }
    const request = {
        resource: model,
        subtype: 'search',
        url: url,
        headers: getHeaders(),
        method: 'GET', 
        body: null,
    };
    const functions = {
        beforeAction: makeRequest.bind(request), 
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function fetchModel(model, options = null, filters = null) {
    let url = apiURL + '/' + model;
    if (options) url += '?per_page=' + options.perPage + '&page=' + (options.page + 1);
    if (filters) {
        if (!options) url += '?';
        for(const filter of Object.keys(filters)) {
            if (filters[filter] !== null && filters[filter] !== '') {
                if (['paciente', 'hospital', 'medico', 'compania'].indexOf(filter) !== -1) {
                    url += '&' + filter + '_id=' + filters[filter];
                } else {
                    url += '&' + filter + '=' + filters[filter];
                }
            }
        }
    }
    const request = {
        resource: model,
        subtype: 'model',
        url: url,
        headers: getHeaders(),
        method: 'GET', 
        body: null
    };
    const functions = {
        beforeAction: makeRequest.bind(request), 
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function createModel(model, details) {
    const url = apiURL + '/' + model;
    const request = {
        resource: model,
        subtype: 'model',
        url: url,
        headers: getHeaders(),
        method: 'POST', 
        body: JSON.stringify(details)
    };
    const functions = {
        beforeAction: makeRequest.bind(request),
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function updateModel(model, id, details) {
    let url = apiURL + '/' + model + '/' + id;
    if (details.merge) {
        url += '/merge';
        details.merge_with = details.merge;
    }
    const request = {
        resource: model,
        subtype: 'model',
        url: url,
        headers: getHeaders(),
        method: 'PUT', 
        body: JSON.stringify(details)
    };
    const functions = {
        beforeAction: makeRequest.bind(request),
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function deleteModel(model, id) {
    const url = apiURL + '/' + model + '/' + id;
    const request = {
        resource: model,
        subtype: 'model',
        url: url,
        headers: getHeaders(),
        method: 'DELETE', 
        body: null
    };
    const functions = {
        beforeAction: makeRequest.bind(request),
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function duplicateModel(model, id) {
    const url = apiURL + '/' + model + '/' + id;
    const request = {
        resource: model,
        subtype: 'model',
        url: url,
        headers: getHeaders(),
        method: 'POST', 
        body: null
    };
    const functions = {
        beforeAction: makeRequest.bind(request),
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function downloadModel(model, options = null, filters = null) {
    let url = apiURL + '/' + model + '/download';
    if (options) url += '?per_page=' + options.perPage + '&page=' + (options.page + 1);
    if (filters) {
        if (!options) url += '?';
        for(const filter of Object.keys(filters)) {
            if (filters[filter] !== null && filters[filter] !== '') {
                if (['paciente', 'hospital', 'medico', 'compania'].indexOf(filter) !== -1) {
                    url += '&' + filter + '_id=' + filters[filter];
                } else {
                    url += '&' + filter + '=' + filters[filter];
                }
            }
        }
    }
    const request = {
        resource: model,
        subtype: 'download',
        url: url,
        headers: getHeaders(),
        method: 'GET', 
        body: null
    };
    const functions = {
        beforeAction: makeRequest.bind(request), 
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function clearModelDownload(model) {
    return dispatch => {
        dispatch({
            type: CLEAR_DOWNLOAD,
            resource: model
        });
    };
}

export function clearModelUpload(model) {
    return dispatch => {
        dispatch({
            type: CLEAR_UPLOAD,
            resource: model
        });
    };
}

export function clearModelPreview(model) {
    return dispatch => {
        dispatch({
            type: CLEAR_PREVIEW,
            resource: model
        });
    };
}

export function updateInforme(data) {
    let url = apiURL + '/informes/current';
    const request = {
        resource: 'informes',
        subtype: 'model',
        url: url,
        headers: getHeaders(),
        method: 'PUT', 
        body: JSON.stringify(data),
    };
    const functions = {
        beforeAction: makeRequest.bind(request), 
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function previewInforme(mes) {
    let url = apiURL + '/informes/preview?mes=' + mes;
    const request = {
        resource: 'informes',
        subtype: 'preview',
        url: url,
        headers: getHeaders(),
        method: 'GET', 
        body: null
    };
    const functions = {
        beforeAction: makeRequest.bind(request), 
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}

export function uploadModel(model, data) {
    let url = apiURL + '/' + model + '/upload';
    const request = {
        resource: model,
        subtype: 'upload',
        url: url,
        headers: getHeaders(),
        method: 'POST', 
        body: data
    };
    const functions = {
        beforeAction: makeRequest.bind(request), 
        unauthorized: clearToken,
        successAction: requestSuccess.bind(request),
        errorAction: requestError.bind(request)
    }
    return requestWrapper(request, functions);
}
