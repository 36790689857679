import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dropdown from './Dropdown';
import {
    updateModel,
    deleteModel
} from '../actions';

class EditSimpleModel extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            values: {
                nombre: this.props.details[1],
                merge: null,
                mergeLabel: ''
            },
            anchorEl: null
        }
        this.state = this.initialState;
        this.reset = this.reset.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleDeleteMenu = this.toggleDeleteMenu.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleMergeChange = this.handleMergeChange.bind(this);
    }

    reset() {
        this.setState(this.initialState);
    }

    submit() {
        this.props.dispatch(updateModel(this.props.model, this.props.details[0], this.state.values));
    }

    delete() {
        this.props.dispatch(deleteModel(this.props.model, this.props.details[0]));
    }

    toggleDeleteMenu(event) {
        let newTarget = null;
        if (!this.state.anchorEl) {
            newTarget = event.currentTarget;
        }
        this.setState({ anchorEl: newTarget });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request.isRequesting === true && 
            this.props.request.isRequesting === false &&
            this.props.request.resource === this.props.model &&
            (this.props.request.method === 'DELETE' || this.props.request.method === 'PUT') &&
            this.props.request.success === true
        ) {
            this.props.update();
            this.props.close();
        }
    }

    changeValue(key, value) {
        let newValues = {[key]: value};
        const values = Object.assign({}, this.state.values, newValues);
        this.setState({values: values});
    }

    handleMergeChange(toMerge) {
        const values = Object.assign({}, this.state.values, {merge: toMerge.value, mergeLabel: toMerge.label});
        this.setState({values: values});
    }

    handleKeyPress(event) {
        if (event.key === 'Enter' && this.isComplete()) {
            this.submit();
        }
    }

    isComplete() {
        return this.state.values.nombre !== '';
    }

    render() {
        const { anchorEl } = this.state;
        return (
            <Dialog open={true} onClose={this.props.close} fullWidth={true} maxWidth='lg' onKeyPress={this.handleKeyPress}>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                id="nombre"
                                label="Nombre"
                                value={this.state.values.nombre}
                                onChange={(event) => 
                                    this.changeValue('nombre', event.target.value)
                                }
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Unir (cuidado!)</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div style={{width: '100%'}}>
                                        <Dropdown 
                                            model={this.props.model}
                                            name='Unir con'
                                            value={{
                                                value: this.state.values.merge,
                                                label: this.state.values.mergeLabel
                                            }}
                                            changeValue={value => this.handleMergeChange(value)} 
                                        />
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {this.props.details[2] === 0 ? (
                        <Fragment>
                            <Button 
                                variant="contained" 
                                aria-owns={anchorEl ? 'delete-menu' : undefined}
                                aria-haspopup='true'
                                onClick={this.toggleDeleteMenu}
                                color='secondary'
                            >
                                Borrar
                            </Button>
                            <Menu
                                id='delete-menu'
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.toggleDeleteMenu}
                            >
                                <MenuItem onClick={this.delete}>De verdad</MenuItem>
                            </Menu>
                        </Fragment>
                    ) : null 
                    }
                    <Button variant="contained" onClick={this.reset} color='default'>
                        Restaurar
                    </Button>
                    <Button variant="contained" onClick={this.submit} disabled={!this.isComplete()} color='primary'>
                        Editar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EditSimpleModel.propTypes = {
};

function mapStateToProps(state) {
    return {
        request: state.request
    }
}

export default connect(mapStateToProps)(EditSimpleModel);
