import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
    createModel 
} from '../actions';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2
    },
    heading: {
        padding: '8px 8px 16px 8px'
    },
    buttonContainer: {
        textAlign: 'right'
    },
    button: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit
    }
});


class NewSimpleModel extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            nombre: '',
        }
        this.state = this.initialState;
        this.reset = this.reset.bind(this);
        this.submit = this.submit.bind(this);
        this.changeNombre = this.changeNombre.bind(this);
    }

    changeNombre(value) {
        this.setState({nombre: value}); 
    }

    reset() {
        this.setState(this.initialState);
    }

    submit() {
        this.props.dispatch(createModel(this.props.model, this.state));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request.isRequesting === true && 
            this.props.request.isRequesting === false &&
            this.props.request.resource === this.props.model &&
            this.props.request.method === 'POST' &&
            this.props.request.success === true
        ) {
            this.reset();
            this.props.created();
        }
    }

    isComplete() {
        return this.state.nombre !== '';
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={4}>
                <div className={classes.heading}>
                    <Typography variant="h6" component="h6">
                        Crear Nuevos {this.props.title}
                    </Typography>
                </div>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <TextField
                            id="nombre"
                            label="Nombre"
                            value={this.state.nombre}
                            onChange={(event) => 
                                this.changeNombre(event.target.value)
                            }
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" className={classes.button} onClick={this.reset}>
                        Limpiar
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.button} 
                        onClick={this.submit} 
                        disabled={!this.isComplete()}
                    >
                        Crear
                    </Button>
                </div>
            </Paper>
        );
    }
}

NewSimpleModel.propTypes = {
    classes: PropTypes.object.isRequired,
    request: PropTypes.shape({
        isRequesting: PropTypes.bool, 
        resource: PropTypes.string, 
        method: PropTypes.string,
        success: PropTypes.bool,
        error: PropTypes.bool
    })
};

function mapStateToProps(state) {
    return {
        request: state.request
    }
}

export default withStyles(styles)(connect(mapStateToProps)(NewSimpleModel));

