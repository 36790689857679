import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { standardOptions, tipoDeActoColumns } from '../datatables';
import EditTipoDeActo from './EditTipoDeActo';
import NewTipoDeActo from './NewTipoDeActo';
import TiposDeActosFilters from './TiposDeActosFilters';
import {
    fetchModel,
} from '../actions';


class TiposDeActos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                perPage: 10, 
                page: 0,
            },
            filters: {
            },
            activeTipoDeActo: null,
        };
    }

    fetchTiposDeActos() {
        this.props.dispatch(fetchModel('tipos_de_actos', this.state.options, this.state.filters));
    }
    
    componentDidMount() {
        this.fetchTiposDeActos();
    }

    handleTableChange(key, value) {
        const newOptions = Object.assign({}, this.state.options, {[key]: value});
        this.setState({options: newOptions}, () => this.fetchTiposDeActos());
    }

    handleFiltersChange(newFilters) {
        this.setState({filters: newFilters}, () => this.fetchTiposDeActos());
    }

    dataTableOptions() {
        return {
            ...standardOptions,
            rowsPerPage: this.state.options.perPage,
            page: this.state.options.page,
            count: this.props.tipos_de_actos.total,
            onChangeRowsPerPage: rows => {this.handleTableChange('perPage', rows)},
            onChangePage: page => {this.handleTableChange('page', page)},
            onRowClick: tipoDeActo => {this.setState({activeTipoDeActo: tipoDeActo})}
        };
    }

    activeTipoDeActo() {
        if (this.state.activeTipoDeActo) {
            return (
                <EditTipoDeActo 
                    details={this.state.activeTipoDeActo} 
                    close={() => this.setState({activeTipoDeActo: null})} 
                    update={()=> this.fetchTiposDeActos()}
                />
            );
        }
    }

    render() {
        return (
            <div>
                <NewTipoDeActo created={() => this.fetchTiposDeActos()} />
                <TiposDeActosFilters submit={(filters) => this.handleFiltersChange(filters)}/>
                <MUIDataTable 
                    title={'Tipos de Actos'} 
                    data={this.props.tipos_de_actos.items} 
                    columns={tipoDeActoColumns} 
                    options={this.dataTableOptions()} 
                />
                {this.activeTipoDeActo()}
            </div>
        )
    }
}

TiposDeActos.propTypes = {
    tipos_de_actos: PropTypes.shape({
        items: PropTypes.array,
        total: PropTypes.number,
        page: PropTypes.number,
        pages: PropTypes.number,
    }),
}

function mapStateToProps(state) {
    return {
        tipos_de_actos: state.models.tipos_de_actos,
    }
}

export default connect(mapStateToProps)(TiposDeActos)
