import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  login,
} from '../actions'

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        maxWidth: 400,
        margin: 'auto'
    },
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        this.setState({[target.name]: target.value});
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.submit();
        }
    }

    submit() {
        this.props.dispatch(login({
            username: this.state.username, 
            password: this.state.password
        }));
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={4} onKeyPress={this.handleKeyPress}>
                <Typography variant="h5" component="h3" style={{marginBottom: 10}}>
                    Iniciar sesión
                </Typography>
                <TextField
                    id='username'
                    label='Usuario'
                    margin='normal'
                    name='username'
                    value={this.state.username}
                    onChange={this.handleInputChange}
                />
                <TextField
                    id='password'
                    label='Contraseña'
                    type='password'
                    margin='normal'
                    name='password'
                    value={this.state.password}
                    onChange={this.handleInputChange}
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={this.submit} 
                >
                    Iniciar
                </Button>
            </Paper>
        )
    }
}

Login.propTypes = {
    user: PropTypes.shape({
        isFetching: PropTypes.bool,
        fetchedAt: PropTypes.number,
        token: PropTypes.bool,
        error: PropTypes.string
    }),
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default withStyles(styles)(connect(mapStateToProps)(Login));
