import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dropdown from './Dropdown';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2
    },
    heading: {
        padding: 8
    },
    buttonContainer: {
        textAlign: 'right'
    },
    button: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit
    }
});

class TiposDeActosFilters extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.reset = this.reset.bind(this);
        this.initialState = {
            filters: {
                nombre: '',
                compania: null,
            },
            filterLabels: {
                compania: null,
            }
        };
        this.state = this.initialState;
    }

    handleFilterChange(filter, selection) {
        const filters = Object.assign({}, this.state.filters, {[filter]: selection.value});
        let labels = this.state.filterLabels;
        if (selection.label) {
            labels = Object.assign({}, this.state.filterLabels, {[filter]: selection.label});
        }
        this.setState({filters: filters, filterLabels: labels}); 
    }

    submit() {
        this.props.submit(this.state.filters);
    }

    reset() {
        this.setState(this.initialState, () => this.submit());
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={4}>
                <div className={classes.heading}>
                    <Typography variant="h6" component="h6">
                        Filtros
                    </Typography>
                </div>
                <Grid container spacing={24}>
                    <Grid item xs={12} md={6}>
                        <Dropdown 
                            model='companias' 
                            name='Compañía'
                            value={{
                                value: this.state.filters.compania, 
                                label: this.state.filterLabels.compania
                            }}
                            changeValue={(value) => this.handleFilterChange('compania', value)} 
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="nombre"
                            label="Nombre"
                            value={this.state.filters.nombre}
                            onChange={(event) => 
                                this.handleFilterChange('nombre', {value: event.target.value})
                            }
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" className={classes.button} onClick={this.reset}>
                        Limpiar
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.submit}>
                        Filtrar
                    </Button>
                </div>
            </Paper>
        );
    }
}

TiposDeActosFilters.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TiposDeActosFilters);

