import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ActoForm from './ActoForm';
import {
    createModel 
} from '../actions';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2
    },
    heading: {
        padding: '8px 8px 16px 8px'
    },
    buttonContainer: {
        textAlign: 'right'
    },
    button: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit
    }
});


class NewActo extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            values: {
                fecha: new Date().toISOString().slice(0,10),
                hospital_id: null,
                paciente_id: null,
                medico_id: null,
                compania_id: null,
                tipo_de_acto_id: null,
                TVS: true,
                tarjeta: '',
                talon: '', 
                autorizacion: '',
                observaciones: ''
            },
            labels: {
                hospital_id: null,
                paciente_id: null,
                medico_id: null,
                compania_id: null,
                tipo_de_acto_id: null,
            }
        }
        this.state = this.initialState;
        this.reset = this.reset.bind(this);
        this.submit = this.submit.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(key, value, label = null, extras = null, clear = null) {
        let newValues = {[key]: value};
        let newLabels = {[key]: label};
        if (extras) {
            newValues.compania_id = extras.compania_id;
            newValues.tarjeta = extras.tarjeta;
            newLabels.compania_id = extras.compania_nombre;
            if (extras.tarjeta) newValues.TVS = false;
        }
        if (clear) {
            newValues[clear] = null;
            newLabels[clear] = null;
        }
        const values = Object.assign({}, this.state.values, newValues);
        const labels = Object.assign({}, this.state.labels, newLabels);
        this.setState({values: values, labels: labels}); 
    }

    reset() {
        this.setState(this.initialState);
    }

    submit() {
        this.props.dispatch(createModel('actos', this.state.values));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request.isRequesting === true && 
            prevProps.request.method === 'POST' &&
            prevProps.request.resource === 'actos' &&
            this.props.request.isRequesting === false &&
            this.props.request.error === false) {
            this.reset();
            this.props.created();
        }
    }

    isComplete() {
        const vals = this.state.values;
        return (
            vals.fecha !== null &&
            vals.hospital_id !== null &&
            vals.paciente_id !== null &&
            vals.medico_id !== null &&
            vals.compania_id !== null &&
            vals.tipo_de_acto_id !== null &&
            (vals.tarjeta !== '' || vals.TVS)
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={4}>
                <div className={classes.heading}>
                    <Typography variant="h6" component="h6">
                        Nuevo Acto
                    </Typography>
                </div>
                <ActoForm 
                    values={this.state.values} 
                    labels={this.state.labels} 
                    changeValue={this.changeValue}            
                />
                <div className={classes.buttonContainer}>
                    <Button variant="contained" className={classes.button} onClick={this.reset}>
                        Limpiar
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.button} 
                        onClick={this.submit} 
                        disabled={!this.isComplete()}
                    >
                        Crear
                    </Button>
                </div>
            </Paper>
        );
    }
}

NewActo.propTypes = {
    classes: PropTypes.object.isRequired,
    request: PropTypes.shape({
        isRequesting: PropTypes.bool, 
        resource: PropTypes.string, 
        method: PropTypes.string,
        success: PropTypes.bool,
        error: PropTypes.bool
    })
}

function mapStateToProps(state) {
    return {
        request: state.request
    }
}

export default withStyles(styles)(connect(mapStateToProps)(NewActo));
