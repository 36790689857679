import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import esLocale from "date-fns/locale/es";
import Dropdown from './Dropdown';

const styles = theme => ({
    root: {
        padding: 16,
        marginBottom: 16,
    },
    heading: {
        padding: 8
    },
    buttonContainer: {
        textAlign: 'right'
    },
    button: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit
    }
});

class ActosFilters extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.reset = this.reset.bind(this);
        this.initialState = {
            filters: {
                fecha_inicial: null,
                fecha_final: null,
                hospital: null,
                paciente: null,
                medico: null,
                compania: null,
                informe: null,
                tipo_de_acto: ''
            },
            filterLabels: {
                hospital: null,
                paciente: null,
                medico: null,
                compania: null,
                informe: null
            }
        };
        this.state = this.initialState;
    }

    handleFilterChange(filter, selection) {
        const newFilters = Object.assign({}, this.state.filters, {[filter]: selection.value});
        const newFilterLabels = Object.assign({}, this.state.filterLabels, {[filter]: selection.label});
        this.setState({filters: newFilters, filterLabels: newFilterLabels}); 
    }

    handleDateChange(filter, date) {
        let newDate = null;
        if (date) {
            let month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            newDate = [year, month, day].join('-');
        }
        const newFilters = Object.assign({}, this.state.filters, {[filter]: newDate});
        this.setState({filters: newFilters}); 
    }

    submit() {
        this.props.submit(this.state.filters);
    }

    reset() {
        this.setState(this.initialState, () => this.submit());
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={4}>
                <div className={classes.heading}>
                    <Typography variant="h6" component="h6">
                        Filtros
                    </Typography>
                </div>
                <Grid container spacing={24}>
                    <Grid item xs={12} md={4}>
                        <Dropdown 
                            model='informes' 
                            name='Informe'
                            value={{
                                value: this.state.filters.informe, 
                                label: this.state.filterLabels.informe
                            }}
                            label={this.state.filterLabels.informe}
                            changeValue={(value) => this.handleFilterChange('informe', value)} 
                        />
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <Grid item xs={12} md={4}>
                            <DatePicker
                                clearable
                                clearLabel='Limpiar'
                                cancelLabel='Cancelar'
                                format="yyyy-MM-dd"
                                margin='normal'
                                label='Fecha inicial'
                                value={this.state.filters.fecha_inicial}
                                onChange={(date) => this.handleDateChange('fecha_inicial', date)}
                            /> 
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DatePicker
                                clearable
                                clearLabel='Limpiar'
                                cancelLabel='Cancelar'
                                format="yyyy-MM-dd"
                                margin='normal'
                                label='Fecha final'
                                value={this.state.filters.fecha_final}
                                onChange={(date) => this.handleDateChange('fecha_final', date)}
                            /> 
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={12} md={4}>
                        <Dropdown 
                            model='companias' 
                            name='Compañía'
                            value={{
                                value: this.state.filters.compania, 
                                label: this.state.filterLabels.compania
                            }}
                            changeValue={(value) => this.handleFilterChange('compania', value)} 
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Dropdown 
                            model='hospitales' 
                            name='Hospital'
                            value={{
                                value: this.state.filters.hospital, 
                                label: this.state.filterLabels.hospital
                            }}
                            changeValue={(value) => this.handleFilterChange('hospital', value)} 
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            id="tipo_de_acto"
                            label="Tipo de Acto"
                            value={this.state.filters.tipo_de_acto}
                            onChange={(event) => 
                                this.handleFilterChange('tipo_de_acto', {value: event.target.value})
                            }
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Dropdown 
                            model='pacientes' 
                            name='Paciente'
                            value={{
                                value: this.state.filters.paciente, 
                                label: this.state.filterLabels.paciente
                            }}
                            label={this.state.filterLabels.paciente}
                            changeValue={(value) => this.handleFilterChange('paciente', value)} 
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Dropdown 
                            model='medicos' 
                            name='Medico'
                            value={{
                                value: this.state.filters.medico, 
                                label: this.state.filterLabels.medico
                            }}
                            changeValue={(value) => this.handleFilterChange('medico', value)} 
                        />
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" className={classes.button} onClick={this.reset}>
                        Limpiar
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.submit}>
                        Filtrar
                    </Button>
                </div>
            </Paper>
        );
    }
}

ActosFilters.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActosFilters);
