const prefixes = {
    requesting: {
        GET: 'Descargando ',
        POST: 'Creando ',
        PUT: 'Actualizando ',
        DELETE: 'Borrando '
    },
    success: {
        GET: 'Se han descargando ',
        POST: 'Se ha creado ',
        PUT: 'Se ha actualizado ',
        DELETE: 'Se ha borrado '
    },
    error: {
        GET: 'No fue posible descargar ',
        POST: 'No fue posible crear ',
        PUT: 'No fue posible actualizar ',
        DELETE: 'No fue posible borrar '
    },
};

const resources = {
    actos: {
        singular: 'el acto',
        plural: 'los actos'
    },
    tipos_de_actos: {
        singular: 'el tipo de acto',
        plural: 'los tipos de actos'
    },
    pacientes: {
        singular: 'el paciente',
        plural: 'los pacientes'
    },
    hospitales: {
        singular: 'el hospital',
        plural: 'los hospitales'
    },
    medicos: {
        singular: 'el medico',
        plural: 'los medicos'
    },
    companias: {
        singular: 'la compañía',
        plural: 'las compañías'
    },
    logins: {
        singular: 'la sesión',
        plural: 'las sesiónes'
    },
    token: {
        singular: 'el token', 
        plural: 'los tokens'
    },
    informes: {
        singular: 'el informe', 
        plural: 'los informes'
    }
};

const overrides = {
    requesting: {
        token: {
            POST: {
                text: 'Comprobando credenciales',
                display: true
            },
            DELETE: {
                text: 'Cerrando sesión',
                display: true
            }
        },
        informes: {
            GET: {
                text: 'Generando informe',
                display: true
            }
        }
    },
    success: {
        actos: {
            GET: {
                text: 'Actos descargados',
                display: false
            }
        },
        token: {
            POST: {
                text: 'Sesión iniciada',
                display: true
            },
            DELETE: {
                text: 'Sesión cerrada',
                display: true
            }
        },
    },
    error: {
        token: {
            POST: {
                text: 'No se puede iniciar la sesión',
                display: true
            },
            DELETE: {
                text: 'No podia borrar el token',
                display: false
            }
        },
    },
};

export default function generateMessage(action, resource, method) {
    if (overrides.hasOwnProperty(action) &&
        overrides[action].hasOwnProperty(resource) &&
        overrides[action][resource].hasOwnProperty(method)) {
        return overrides[action][resource][method];
    } else {
        let message = {display: true};
        if (method === 'GET') {
            message.text = prefixes[action][method] + resources[resource].plural;
            if (action === 'success') {
                message.display = false;
            }
        } else {
            message.text = prefixes[action][method] + resources[resource].singular;
        }
        return message;
    }
}
