import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { standardOptions, actoColumns } from '../datatables';
import EditActo from './EditActo';
import NewActo from './NewActo';
import ActosFilters from './ActosFilters';
import DownloadModel from './DownloadModel';
import {
    fetchModel,
    downloadModel,
    clearModelDownload
} from '../actions';


class Actos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                perPage: 10, 
                page: 0,
            },
            filters: {
            },
            activeActo: null,
        };
        this.getDownloadLink = this.getDownloadLink.bind(this);
        this.clearDownloadLink = this.clearDownloadLink.bind(this);
    }

    fetchActos() {
        this.props.dispatch(fetchModel('actos', this.state.options, this.state.filters));
    }
    
    componentDidMount() {
        this.fetchActos();
    }

    handleTableChange(key, value) {
        const newOptions = Object.assign({}, this.state.options, {[key]: value});
        this.setState({options: newOptions}, () => this.fetchActos());
    }

    handleFiltersChange(newFilters) {
        this.setState({filters: newFilters}, () => this.fetchActos());
    }
    
    getDownloadLink() {
        this.props.dispatch(downloadModel('actos', this.state.options, this.state.filters));
    }

    clearDownloadLink() {
        this.props.dispatch(clearModelDownload('actos'));
    }

    dataTableOptions() {
        return {
            ...standardOptions,
            rowsPerPage: this.state.options.perPage,
            page: this.state.options.page,
            count: this.props.actos.total,
            onChangeRowsPerPage: rows => {this.handleTableChange('perPage', rows)},
            onChangePage: page => {this.handleTableChange('page', page)},
            onRowClick: acto => {this.setState({activeActo: acto})}
        };
    }

    activeActo() {
        if (this.state.activeActo) {
            return (
                <EditActo 
                    details={this.state.activeActo} 
                    close={() => this.setState({activeActo: null})} 
                    update={()=> this.fetchActos()}
                />
            );
        }
    }

    render() {
        return (
            <div>
                <NewActo created={() => this.fetchActos()} />
                <ActosFilters submit={(filters) => this.handleFiltersChange(filters)}/>
                <MUIDataTable 
                    title={'Actos'} 
                    data={this.props.actos.items} 
                    columns={actoColumns} 
                    options={this.dataTableOptions()} 
                />
                <DownloadModel 
                    download={this.getDownloadLink} 
                    link={this.props.actos.downloadLink} 
                    clear={this.clearDownloadLink} 
                />
                {this.activeActo()}
            </div>
        )
    }
}

Actos.propTypes = {
    actos: PropTypes.shape({
        items: PropTypes.array,
        total: PropTypes.number,
        page: PropTypes.number,
        pages: PropTypes.number,
    }),
    request: PropTypes.shape({
        isRequesting: PropTypes.bool, 
        resource: PropTypes.string, 
        method: PropTypes.string,
        success: PropTypes.bool,
        error: PropTypes.bool
    })
}

function mapStateToProps(state) {
    return {
        actos: state.models.actos,
        request: state.request
    }
}

export default connect(mapStateToProps)(Actos)
