import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    buttonContainer: {
        textAlign: 'right'
    },
    button: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit
    }
});

class DownloadModel extends Component {

    constructor(props) {
        super(props);
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    handleLinkClick() {
        this.props.clear();
        window.location.assign(this.props.link);
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={4}>
                <div className={classes.buttonContainer}>
                    {(this.props.link) ?
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.handleLinkClick}>
                            Descargar
                        </Button>
                        :
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.props.download}>
                            Generar descarga
                        </Button>
                    }
                </div>
            </Paper>
        );
    }
}

DownloadModel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DownloadModel);
