import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import generateMessage from '../messages';

class Notification extends Component {

    constructor(props) {
        super(props);
        this.requestingNotificatino = null;
    }

    componentDidUpdate(prevProps) {
        const resource = this.props.request.resource;
        const method = this.props.request.method;
        if (!this.props.request.search && prevProps.request.isRequesting === false && this.props.request.isRequesting === true) {
            const message = generateMessage('requesting', resource, method);
            if (message.display) {
                this.requestingNotification = toast(
                    ({ closeToast }) => <div><CircularProgress size={15} style={{marginRight: 10}} /> {message.text}</div>,
                    { autoClose: false }
                );
            }
        } else if (!this.props.request.search && prevProps.request.isRequesting === true && this.props.request.success === true) {
            const message = generateMessage('success', resource, method);
            if (message.display) {
                toast.update(this.requestingNotification, {
                    render: message.text,
                    type: toast.TYPE.SUCCESS,
                    hideProgressBar: true,
                    autoClose: 2000
                });
            } else {
                toast.dismiss(this.requestingNotification);
            }
        } else if (prevProps.request.isRequesting === true && this.props.request.error === true) {
            const message = generateMessage('error', resource, method);
            if (message.display) {
                toast.update(this.requestingNotification, {
                    render: message.text,
                    type: toast.TYPE.ERROR,
                    hideProgressBar: true,
                    autoClose: 2000
                });
            } else {
                toast.dismiss(this.requestingNotification);
            }
        } else {
            toast.dismiss(this.requestingNotification);
        }
    }

    render() {
        return <ToastContainer />
    }

}

export default Notification;
