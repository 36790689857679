import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import AuthService from '../AuthService';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';
import Nav from './Nav';
import Login from './Login';
import Actos from './Actos';
import TiposDeActos from './TiposDeActos';
import SimpleModel from './SimpleModel';
import Logins from './Logins';
import Informes from './Informes';
import Notification from './Notification';
import {
  logout,
} from '../actions';


class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Nav storedAuthDetails={AuthService.storedDetails()} logout={() => this.props.dispatch(logout())}/>
                <Notification request={this.props.request} />
                <div className='container' style={{padding: '20px 10px 20px 10px'}}>
                    { JSON.stringify(this.props.token) }
                    <Route 
                        exact path="/login"
                        render={() => (
                            AuthService.hasToken() ? (
                                <Redirect to="/"/>
                            ) : (
                                <Login/>
                        ))}
                    />
                    <PrivateRoute path="/" exact component={Actos} model="actos" title="Actos" />
                    <PrivateRoute path="/tipos_de_actos" exact component={TiposDeActos} model="tipos_de_actos" title="Tipos de Actos" />
                    <PrivateRoute path="/pacientes" exact component={SimpleModel} model="pacientes" title="Pacientes" />
                    <PrivateRoute path="/medicos" exact component={SimpleModel} model="medicos" title="Medicos" />
                    <PrivateRoute path="/hospitales" exact component={SimpleModel} model="hospitales" title="Hospitales" />
                    <PrivateRoute path="/companias" exact component={SimpleModel} model="companias" title="Compañías" />
                    <PrivateRoute path="/informes" exact component={Informes} title="Informes" />
                    <PrivateRoute path="/sesiones" exact component={Logins} title="Sesiónes" />
                </div>
            </MuiThemeProvider>
        )
    }
}

App.propTypes = {
    request: PropTypes.shape({
        isRequesting: PropTypes.bool, 
        resource: PropTypes.string, 
        method: PropTypes.string,
        success: PropTypes.bool,
        error: PropTypes.bool
    }),
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return {
        request: state.request
    }
}

export default connect(mapStateToProps)(App);
