import { combineReducers } from 'redux'
import {
    MAKE_REQUEST,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    
    CLEAR_DOWNLOAD,
    CLEAR_UPLOAD,
    CLEAR_PREVIEW
} from './actions'

function request(
    state = {
        isRequesting: false, 
        resource: null, 
        method: null,
        success: null,
        error: null,
        search: false
    },
    action
) {
    switch (action.type) {
        case MAKE_REQUEST:
            return Object.assign({}, state, {
                isRequesting: true,
                resource: action.resource,
                method: action.method,
                error: null,
                search: action.search
            });
        case REQUEST_SUCCESS:
            return Object.assign({}, state, {
                isRequesting: false,
                resource: action.resource,
                method: action.method,
                success: true,
                error: false,
                search: action.search
            });
        case REQUEST_ERROR:
            return Object.assign({}, state, {
                isRequesting: false,
                resource: action.resource,
                method: action.method,
                success: false,
                error: true,
                search: action.search
            });
        default:
            return state;
    }
}

const modelFormat = {
    items: [], 
    total: 0, 
    page: 1, 
    pages: 1, 
    searchResults: [],
    downloadLink: null,
    upload: {
        errorDownloadLink: null,
        successCount: null,
        errorCount: null
    }
}

function models(
    state = {
        actos: modelFormat, 
        tipos_de_actos: modelFormat, 
        pacientes: modelFormat, 
        medicos: modelFormat, 
        hospitales: modelFormat, 
        companias: modelFormat,
        logins: modelFormat,
        informes: {searchResults: [], downloadLink: null, preview: null},
    },
    action
) {
    if (action.type === REQUEST_SUCCESS) {
        if (action.subtype === 'search') {
            const newModelState = Object.assign({}, state[action.resource], {
                searchResults: action.response,
            });
            return Object.assign({}, state, {
                [action.resource]: newModelState
            });
        } else if (action.subtype === 'download') {
            const newModelState = Object.assign({}, state[action.resource], {
                downloadLink: action.response.download,
            });
            return Object.assign({}, state, {
                [action.resource]: newModelState
            });
        } else if (action.subtype === 'upload') {
            const newModelState = Object.assign({}, state[action.resource], {
                upload: {
                    successCount: action.response.success_count,
                    errorCount: action.response.error_count,
                    errorDownloadLink: action.response.error_download,
                }
            });
            return Object.assign({}, state, {
                [action.resource]: newModelState
            });
        } else if (action.subtype === 'preview') {
            const newModelState = Object.assign({}, state[action.resource], {
                preview: action.response.html,
            });
            return Object.assign({}, state, {
                [action.resource]: newModelState
            });
        } else if (action.subtype === 'model' && action.method === 'GET') {
            const newModelState = Object.assign({}, state[action.resource], {
                items: action.response.data,
                total: action.response.total,
                page: action.response.current_page,
                pages: action.response.last_page,
            });
            return Object.assign({}, state, {
                [action.resource]: newModelState
            });
        } else {
            return state;
        }
    } else if (action.type === CLEAR_DOWNLOAD) {
        const newModelState = Object.assign({}, state[action.resource], {
            downloadLink: null
        });
        return Object.assign({}, state, {
            [action.resource]: newModelState
        });
    } else if (action.type === CLEAR_UPLOAD) {
        const newModelState = Object.assign({}, state[action.resource], {
            upload: {
                successCount: null,
                errorCount: null,
                errorDownloadLink: null
            }
        });
        return Object.assign({}, state, {
            [action.resource]: newModelState
        });
    } else if (action.type === CLEAR_PREVIEW) {
        const newModelState = Object.assign({}, state[action.resource], {
            preview: null
        });
        return Object.assign({}, state, {
            [action.resource]: newModelState
        });
    } else {
        return state;
    }
}


const rootReducer = combineReducers({
    request,
    models,
});

export default rootReducer;
