const AuthService =  {

    getToken: function() {
        return localStorage.getItem('token');
    },

    getRole: function() {
        return localStorage.getItem('role');
    },

    storeDetails: function(json) {
        localStorage.setItem('token', json.token);
        localStorage.setItem('role', json.role);
    },

    hasToken: function() {
        return this.getToken() !== null;
    },

    clearToken: function() {
        localStorage.removeItem('token');
    },
    
    storedDetails: function() {
        return {
            token: this.getToken(),
            role: this.getRole(),

        }
    },

    isAdmin: function() {
        return this.getRole >= 1;
    }
}

export default AuthService;
