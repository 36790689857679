import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { standardOptions, simpleModelColumns } from '../datatables';
import EditSimpleModel from './EditSimpleModel';
import NewSimpleModel from './NewSimpleModel';
import SimpleModelFilters from './SimpleModelFilters';
import {
    fetchModel,
} from '../actions';


class SimpleModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                perPage: 10, 
                page: 0,
            },
            filters: {
            },
            activeModel: null,
        };
    }

    fetchModel() {
        this.props.dispatch(fetchModel(this.props.model, this.state.options, this.state.filters));
    }
    
    componentDidMount() {
        this.fetchModel();
    }

    handleTableChange(key, value) {
        const newOptions = Object.assign({}, this.state.options, {[key]: value});
        this.setState({options: newOptions}, () => this.fetchModel());
    }

    handleFiltersChange(newFilters) {
        this.setState({filters: newFilters}, () => this.fetchModel());
    }

    dataTableOptions() {
        return {
            ...standardOptions,
            rowsPerPage: this.state.options.perPage,
            page: this.state.options.page,
            count: this.props.data.total,
            onChangeRowsPerPage: rows => {this.handleTableChange('perPage', rows)},
            onChangePage: page => {this.handleTableChange('page', page)},
            onRowClick: model => {this.setState({activeModel: model})}
        };
    }

    activeModel() {
        if (this.state.activeModel) {
            return (
                <EditSimpleModel 
                    model={this.props.model}
                    details={this.state.activeModel} 
                    close={() => this.setState({activeModel: null})} 
                    update={()=> this.fetchModel()}
                />
            );
        }
    }

    render() {
        return (
            <div>
                <NewSimpleModel model={this.props.model} title={this.props.title} created={() => this.fetchModel()} />
                <SimpleModelFilters submit={(filters) => this.handleFiltersChange(filters)}/>
                <MUIDataTable 
                    title={this.props.title} 
                    data={this.props.data.items} 
                    columns={simpleModelColumns} 
                    options={this.dataTableOptions()} 
                />
                {this.activeModel()}
            </div>
        )
    }
}

SimpleModel.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.array,
        total: PropTypes.number,
        page: PropTypes.number,
        pages: PropTypes.number,
    }),
}

function mapStateToProps(state, ownProps) {
    return {
        data: state.models[ownProps.model]
    }
}

export default connect(mapStateToProps)(SimpleModel)
