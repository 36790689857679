import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: blue,
        secondary: {
            main: '#f44336',
        },
    },
    overrides: {
        MuiPaper : {
            root: {
                // padding: 16,
                // marginBottom: 16,
            }
        },
        MUIDataTableBodyCell: {
            root: {
                paddingRight: 12,
                paddingLeft: 12,
                fontSize: '.75rem',
                cursor: 'pointer'
            }
        },
        MUIDataTableHeadCell: {
            root: {
                paddingRight: 12,
                paddingLeft: 12,
                fontSize: '.75rem'
            }
        },
        MuiFormControl: {
            root: {
                width: '100%'
            },
            marginNormal: {
                marginTop: 0,
                position: 'relative',
                top: -12
            }
        },
        MuiDialogContent: {
            root: {
                overflowY: 'visible'
            }
        }
    }
});

export default theme;
