import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { standardOptions, loginsColumns } from '../datatables';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {
    fetchModel,
    logout,
} from '../actions';


class Logins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                perPage: 10, 
                page: 0,
            },
        };
        this.logoutAll = this.logoutAll.bind(this);
    }

    fetchModel() {
        this.props.dispatch(fetchModel('logins', this.state.options, null));
    }
    
    componentDidMount() {
        this.fetchModel();
    }

    handleTableChange(key, value) {
        const newOptions = Object.assign({}, this.state.options, {[key]: value});
        this.setState({options: newOptions}, () => this.fetchModel());
    }

    logoutAll() {
        this.props.dispatch(logout(true));
    }

    dataTableOptions() {
        return {
            ...standardOptions,
            rowsPerPage: this.state.options.perPage,
            page: this.state.options.page,
            count: this.props.data.total,
            onChangeRowsPerPage: rows => {this.handleTableChange('perPage', rows)},
            onChangePage: page => {this.handleTableChange('page', page)},
        };
    }

    render() {
        return (
            <div>
                <MUIDataTable 
                    title={this.props.title} 
                    data={this.props.data.items} 
                    columns={loginsColumns} 
                    options={this.dataTableOptions()} 
                />
                <Paper elevation={4}>
                    <div style={{textAlign: 'right'}}>
                        <Button variant="contained" color="primary" onClick={this.logoutAll}>
                            Cerrar todas las sesiónes
                        </Button>
                    </div>
                </Paper>
            </div>
        )
    }
}

Logins.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.array,
        total: PropTypes.number,
        page: PropTypes.number,
        pages: PropTypes.number,
    }),
}

function mapStateToProps(state, ownProps) {
    return {
        data: state.models.logins
    }
}

export default connect(mapStateToProps)(Logins)
