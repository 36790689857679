import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import esLocale from "date-fns/locale/es";
import Dropdown from './Dropdown';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

export default function ActoForm(props) {

    function handleDateChange(field, date) {
        let returnDate = null;
        if (date) {
            returnDate = date.toISOString().slice(0,10); 
        }
        props.changeValue(field, returnDate);
    }

    function handleDropdownChange(field, selected) {
        let extras = null;
        if (selected.hasOwnProperty('compania_id') && selected.hasOwnProperty('tarjeta')) {
            extras = {
                compania_id: selected.compania_id,
                compania_nombre: selected.compania_nombre,
                tarjeta: selected.tarjeta,
            };
        }
        let clear = null;
        if (field === 'compania_id' && selected.value === null) {
            clear = 'tipo_de_acto_id';
        }
        props.changeValue(field, selected.value, selected.label, extras, clear);
    }

    function handleTextChange(field, event) {
        props.changeValue(field, event.target.value);
    }

    function handleCheckboxChange(field, value) {
        props.changeValue(field, value);
    }

    return (
        <Grid container spacing={24}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <Grid item xs={12} md={4}>
                    <DatePicker
                        clearable
                        clearLabel='Limpiar'
                        cancelLabel='Cancelar'
                        format="yyyy-MM-dd"
                        margin='normal'
                        label='Fecha'
                        value={props.values.fecha}
                        onChange={(date) => handleDateChange('fecha', date)}
                    /> 
                </Grid>
            </MuiPickersUtilsProvider>
                <Grid item xs={12} md={4}>
                    <Dropdown 
                        model='pacientes' 
                        name='Paciente'
                        value={{
                            value: props.values.paciente_id, 
                            label: props.labels.paciente_id
                        }}
                        label={props.labels.paciente}
                        changeValue={(selected) => handleDropdownChange('paciente_id', selected)} 
                        creatable
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Dropdown 
                        model='hospitales' 
                        name='Hospital'
                        value={{
                            value: props.values.hospital_id, 
                            label: props.labels.hospital_id
                        }}
                        label={props.labels.hospital}
                        changeValue={(selected) => handleDropdownChange('hospital_id', selected)} 
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Dropdown 
                        model='medicos' 
                        name='Medico'
                        value={{
                            value: props.values.medico_id, 
                            label: props.labels.medico_id
                        }}
                        label={props.labels.medico}
                        changeValue={(selected) => handleDropdownChange('medico_id', selected)} 
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Dropdown 
                        model='companias' 
                        name='Compañía'
                        value={{
                            value: props.values.compania_id, 
                            label: props.labels.compania_id
                        }}
                        label={props.labels.compania}
                        changeValue={(selected) => handleDropdownChange('compania_id', selected)} 
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Dropdown 
                        model='tipos_de_actos' 
                        name='Tipo de acto'
                        value={{
                            value: props.values.tipo_de_acto_id, 
                            label: props.labels.tipo_de_acto_id
                        }}
                        label={props.labels.tipo_de_acto}
                        changeValue={(selected) => handleDropdownChange('tipo_de_acto_id', selected)} 
                        compania_id={props.values.compania_id ? props.values.compania_id : false}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(props.values.TVS)}
                                onChange={(event) => handleCheckboxChange('TVS', !Boolean(props.values.TVS))}
                                value="1"
                                color="primary"
                            />
                        }
                    label="TVS"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        disabled={Boolean(props.values.TVS)}
                        id="tarjeta"
                        label="Tarjeta"
                        value={props.values.tarjeta}
                        onChange={(event) => handleTextChange('tarjeta', event)}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        disabled={Boolean(props.values.TVS)}
                        id="talon"
                        label="Talón"
                        value={props.values.talon}
                        onChange={(event) => handleTextChange('talon', event)}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        disabled={Boolean(props.values.TVS)}
                        id="autorizacion"
                        label="Autorización"
                        value={props.values.autorizacion}
                        onChange={(event) => handleTextChange('autorizacion', event)}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        id="observaciones"
                        label="Observaciones"
                        value={props.values.observaciones}
                        onChange={(event) => handleTextChange('observaciones', event)}
                        margin="normal"
                    />
                </Grid>
                { props.informe ?
                    (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                id="informe"
                                label="Informe"
                                value={props.values.informe}
                                onChange={(event) => handleTextChange('informe', event)}
                                margin="normal"
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>)
                : null }
        </Grid>
    );
}
