const textLabels = {
    body: {
        noMatch: 'Discuple, no hay resultados',
        toolTip: 'Sort',
    },
    pagination: {
        next: 'Siguiente página',
        previous: 'Anterior página',
        rowsPerPage: 'Filas por página',
        displayRows: 'de',
    },
    toolbar: {
        search: 'Busca',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
    },
    filter: {
        all: 'TODO',
        title: 'FILTROS',
        reset: 'REINICIAR',
    },
    viewColumns: {
        title: 'Ver Columnas',
        titleAria: 'Ver/Esconder Columnas',
    },
    selectedRows: {
        text: 'Fila(s) seleccionadas',
        delete: 'Borrar',
        deleteAria: 'Borrar filas seleccionadas',
    },
};

const standardOptions = {
    textLabels: textLabels,
    rowsPerPageOptions: [10, 20, 40, 60, 100],
    serverSide: true,
    print: false,
    download: false,
    filter: false,
    selectableRows: false,
    search: false,
    sort: false,
};

const actoColumns = [
    {name: 'id', label: 'ID', options: {display: false}},
    {name: 'fecha', label: 'Fecha'}, 
    {name: 'informe', label: 'Informe'},
    {name: 'paciente', label: 'Paciente'},
    {name: 'hospital', label: 'Hospital'},
    {name: 'medico', label: 'Medico'},
    {name: 'tipo_de_acto', label: 'Tipo de acto'},
    {name: 'compania', label: 'Compañía'},
    {name: 'TVS', label: 'TVS', options: {
        customBodyRender: (value, tableMeta, updateValue) => value ? '✓' : '⨯'
    }},
    {name: 'precio', label: 'Precio', options: {
        customBodyRender: (value, tableMeta, updateValue) => `${value.toFixed(2)}€`.replace('.', ',')
    }},
    {name: 'talon', label: 'Talon', options: {display: false}},
    {name: 'autorizacion', label: 'Autorización', options: {display: false}},
    {name: 'tarjeta', label: 'Tarjeta', options: {display: false}},
    {name: 'observaciones', label: 'Observaciones', options: {display: false}},
    {name: 'paciente_id', label: 'Paciente ID', options: {display: false}},
    {name: 'hospital_id', label: 'Hospital ID', options: {display: false}},
    {name: 'medico_id', label: 'Medico ID', options: {display: false}},
    {name: 'tipo_de_acto_id', label: 'Tipo de acto ID', options: {display: false}},
    {name: 'compania_id', label: 'Compania ID', options: {display: false}},
    {name: 'TVS', label: 'TVS bool', options: {display: false}},
];

const tipoDeActoColumns = [
    {name: 'id', label: 'ID', options: {display: false}},
    {name: 'compania', label: 'Compañía'},
    {name: 'nombre', label: 'Nombre'},
    {name: 'precio', label: 'Precio', options: {
        customBodyRender: (value, tableMeta, updateValue) => `${value.toFixed(2)}€`.replace('.', ',')
    }},
    {name: 'compania_id', label: 'Compania ID', options: {display: false}},
    {name: 'actos_count', label: 'Numero de actos'}
];

const simpleModelColumns = [
    {name: 'id', label: 'ID'},
    {name: 'nombre', label: 'Nombre'},
    {name: 'actos_count', label: 'Numero de actos'}
];

const loginsColumns = [
    {name: 'id', label: 'ID'},
    {name: 'created_at', label: 'Fecha'},
    {name: 'username', label: 'Usuario'},
    {name: 'user_id', label: 'User ID', options: {display: false}},
    {name: 'ip_address', label: 'IP'},
    {name: 'location', label: 'Ubicación'},
    {name: 'token', label: 'Token', options: {display: false}},
    {name: 'user_agent', label: 'Aparato'},
];

export { 
    standardOptions, 
    actoColumns, 
    tipoDeActoColumns, 
    simpleModelColumns, 
    loginsColumns 
};
