import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        flexGrow: 1,
    },
    logo: {
        flexGrow: 1,
        textDecoration: 'none'
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

function menu(details, logout) {
    if (details.token !== null && details.role >= 1) {
        return (
            <Fragment>
                <Button color="inherit" component={Link} to='/tipos_de_actos'>Tipos de Actos</Button>
                <Button color="inherit" component={Link} to='/pacientes'>Pacientes</Button>
                <Button color="inherit" component={Link} to='/medicos'>Medicos</Button>
                <Button color="inherit" component={Link} to='/hospitales'>Hospitales</Button>
                <Button color="inherit" component={Link} to='/companias'>Compañías</Button>
                <Button color="inherit" component={Link} to='/informes'>Informes</Button>
                <Button color="inherit" component={Link} to='/sesiones'>Sesiónes</Button>
                <Button color="inherit" onClick={logout}>Cerrar sesión</Button>
            </Fragment>
        );
    } else if (details.token !== null && details.role == 0) {
        return (
            <Fragment>
                <Button color="inherit" component={Link} to='/pacientes'>Pacientes</Button>
                <Button color="inherit" component={Link} to='/medicos'>Medicos</Button>
                <Button color="inherit" component={Link} to='/hospitales'>Hospitales</Button>
                <Button color="inherit" component={Link} to='/companias'>Compañías</Button>
                <Button color="inherit" onClick={logout}>Cerrar sesión</Button>
            </Fragment>
        );
    } else {
        return (<Button color="inherit" component={Link} to='/login'>Iniciar sesión</Button>);
    }
}

function Nav(props) {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography 
                        variant="h6" 
                        color="inherit" 
                        className={classes.logo} 
                        component={Link} to='/'
                    >
                        SAMA
                    </Typography>
                    {menu(props.storedAuthDetails, props.logout)}
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default withStyles(styles)(Nav);
